<!-- eslint-disable vue/no-v-html -->
<template>
  <BasicButtonDialog
    :visible="visible"
    :dialog-content="dialogContent"
    :show-image="showImage"
    :voucher-button="voucherButton"
    @close="$emit('close')"
    ><slot v-if="$slots.content" name="content"
  /></BasicButtonDialog>
</template>
<script setup lang="ts">
import type { IDialogContent } from '@/@types/dialog-content';

defineProps({
  visible: {
    //Resolves to :property-foo=""
    type: Boolean,
    required: true,
  },
  dialogContent: {
    type: Object as PropType<IDialogContent>,
    required: false,
    default: null,
  },
  showImage: {
    type: Boolean,
    required: false,
    default: true,
  },
  voucherButton: {
    type: Boolean,
    required: false,
    default: false,
  },
});

defineEmits<{
  (e: 'close'): string;
}>();

const BasicButtonDialog = defineAsyncComponent(() => {
  if (useSiteIdent() === SiteIdent.default) {
    return import('./vdv/basic-button-dialog.vue');
  } else {
    return import('./minilu/basic-button-dialog.vue');
  }
});
</script>
<style scoped lang="postcss"></style>
